import React, { Component } from 'react';
import './logoSection.css';
import DesignSection from './designSection';

export default class ImagesSection extends Component {
  render() {
    return (
      <div className="sectionContainer">
        <DesignSection
          title={'LinkedIn Banners'}
          subtitle={'These are a set of approved branded images that can be used for employee linkedin profiles or other marketing materials. Grab one to use on your profile.'}>
          <div style={{maxWidth:800}}>
            <div className="tiny-padding">
              <img src={'/design/linkedin-banner-light.jpg'} className="full-width small-bottom-margin" />
              <a className="downloadText" href="/design/linkedin-banner-light.jpg" download>
                Download
                <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
              </a>
            </div>
            <div className="tiny-padding">
              <img src={'/design/linkedin-banner-dark.jpg'} className="full-width small-bottom-margin" />
              <a className="downloadText" href="/design/linkedin-banner-dark.jpg" download>
                Download
                <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
              </a>
            </div>
          </div>
        </DesignSection>
        <DesignSection
          title={'Photography'}
          subtitle={'These images are great examples of backgrounds that should be used for slides and other marketing materials. Grab one to use in your own materials.'}>
          <div className="max-width-container small-bottom-margin">
            <div className="section-padding no-top-padding">
              <div className="">
                <div className="row middle">
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-city-buildings-1.jpg'} className="full-width small-bottom-margin" alt="city buildings image" />
                    <a className="downloadText"  href="design/images-textures/background-city-buildings-1.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-city-buildings-2.jpg'} className="full-width small-bottom-margin" alt="city buildings image" />
                    <a className="downloadText" href="/design/images-textures/background-city-buildings-2.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-city-buildings-3.jpg'} className="full-width small-bottom-margin" alt="city buildings image" />
                    <a className="downloadText" href="/design/images-textures/background-city-buildings-3.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-cityscape.jpg'} className="full-width small-bottom-margin" alt="new york cityscape" />
                    <a className="downloadText"  href="design/images-textures/background-cityscape.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-street-cab.jpg'} className="full-width small-bottom-margin" alt="city street with a moving cab" />
                    <a className="downloadText" href="/design/images-textures/background-street-cab.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-street-lights.jpg'} className="full-width small-bottom-margin" alt="street car lights on a long exposure photo" />
                    <a className="downloadText" href="/design/images-textures/background-street-lights.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-street-pedestrians-1.jpg'} className="full-width small-bottom-margin" alt="street pedestrian" />
                    <a className="downloadText"  href="design/images-textures/background-street-pedestrians-1.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/background-street-pedestrians-2.jpg'} className="full-width small-bottom-margin" alt="street pedestrian image" />
                    <a className="downloadText" href="/design/images-textures/background-street-pedestrians-2.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>

                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-autos.jpg'} className="full-width small-bottom-margin" alt="city buildings image" />
                    <a className="downloadText"  href="design/images-textures/sector-autos.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-dining.jpg'} className="full-width small-bottom-margin" alt="city buildings image" />
                    <a className="downloadText" href="/design/images-textures/sector-dining.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-ecommerce.jpg'} className="full-width small-bottom-margin" alt="city buildings image" />
                    <a className="downloadText" href="/design/images-textures/sector-ecommerce.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-gaming.jpg'} className="full-width small-bottom-margin" alt="new york cityscape" />
                    <a className="downloadText"  href="design/images-textures/sector-gaming.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-investment.jpg'} className="full-width small-bottom-margin" alt="city street with a moving cab" />
                    <a className="downloadText" href="/design/images-textures/sector-investment.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-online-gambling.jpg'} className="full-width small-bottom-margin" alt="street car lights on a long exposure photo" />
                    <a className="downloadText" href="/design/images-textures/sector-online-gambling.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-payments.jpg'} className="full-width small-bottom-margin" alt="street pedestrian" />
                    <a className="downloadText"  href="design/images-textures/sector-payments.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-rideshare.jpg'} className="full-width small-bottom-margin" alt="street pedestrian image" />
                    <a className="downloadText" href="/design/images-textures/sector-rideshare.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/sector-streaming.jpg'} className="full-width small-bottom-margin" alt="street pedestrian image" />
                    <a className="downloadText" href="/design/images-textures/sector-streaming.jpg" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>

                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/texture-triangles.png'} className="full-width small-bottom-margin" alt="background texture geometric shatter triangles in yipit brand colors" />
                    <a className="downloadText"  href="/design/images-textures/texture-triangles.png" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/images-textures/texture-triangles-white.png'} className="full-width small-bottom-margin" alt="white background texture geometric shatter triangles" />
                    <a className="downloadText" href="/design/images-textures/texture-triangles-white.png" download>
                      Download
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DesignSection>
      </div>
    );
  }
}
